var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"map"}},[_c('GmapMap',{ref:"mapRef",attrs:{"id":"mapClassic","center":{ lat: 37.86926, lng: -122.254811 },"zoom":1,"map-type-id":"roadmap","options":{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            draggableCursor: 'crosshair',
            clickableIcons: false,
            gestureHandling: 'greedy',
            maxZoom: 15,
            minZoom: 2
        }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }